import Box from "@mui/material/Box";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import { useParams } from "react-router-dom";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import moment from "moment";
import style from "../Menu.module.scss";
import { CustomButtosWithIcon } from "../../../Components";
import Option from "../../../Components/SvgCompoents/Option";
import ActivityItem from "./ActivityItem";
import CommentItem from "./CommentItem";
import { useAddActivityMutation, useGetActivityQuery, usePrefetch, useSeenActivityMutation } from "../../../store/services/activity";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store";
import { changeLastSeen, setUnseenActivitysCount, unseenActivitysCount } from "../../../store/slice/activitySlice";

interface Props {
    children: any;
}

export default function Activity() {
    const role = localStorage.getItem('rbcRole')
    const [active, setActive] = useState<string>('activity');
    const [comment, setComment] = useState<string>();
    const [page, setPage] = useState<number>(1);
    const unseenCount = useAppSelector((state) => state.activitySlice.unseenCount)
    const notification = useAppSelector((state) => state?.notificationsContainer?.pushNotification);
    const ref = useRef<HTMLInputElement>(null);
    const params = useParams();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { data } = useGetActivityQuery({ task: params?.id, type: active, page: 1, limit: 10000 });
    // const { data: count } = useGetUnseenActivitiesCountQuery(params?.id);
    const [addActivity, { isSuccess }] = useAddActivityMutation();
    // const [seenActivity] = useSeenActivityMutation();
    const handleChangeType = useCallback((str: string) => { setActive(str) }, []);
    const handleChangePage = useCallback((page: number) => { setPage(page) }, []);
    const prefetchPage = usePrefetch('getActivity');
    // const hrefSplit=window.location.href.split('/');
    const handleWriteComment = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
        setComment(e.target.value);
    }, []);

    useEffect(() => {
        dispatch(unseenActivitysCount(params?.id))
    }, [])
    useEffect(() => {
        if (active === 'comment' && unseenCount !== 0) {
            dispatch(changeLastSeen({ id: params.id, activity: data?.results[0]?.id }));            
            // dispatch(setUnseenActivitysCount())
        }
    }, [data, active])
    useEffect(() => {
        if (params?.id && window.location.href.includes('profile/dashboard/')) {    
            prefetchPage({ task: params?.id, type: active, page: 1, limit: 10000 }, { force: true });        
        }
    }, [active])
    useEffect(() => {
        console.log(notification,'notificationnotification');
        
        if (notification?.data?.type === 'task' && params?.id && window.location.href.includes('profile/dashboard/')) {
            prefetchPage({ task: params?.id, type: active, page: 1, limit: 10000 }, { force: true })
            if (active === 'comment') {
                dispatch(setUnseenActivitysCount())
            } else {
                dispatch(unseenActivitysCount(params?.id))
            }
        }
    }, [notification]);


    return (
        <Box>
            <div className="d-flex justify-content-between">
                <div className={style.activity}>{t('recentActivity')}</div>
                <Option />
            </div>
            <Box sx={{ display: "flex", marginBottom: '10px' }}>
                <div className="position-relative">
                    <Button
                        className={active === "comment" ? "primary tabButton" : "tabButton"}
                        onClick={() => handleChangeType('comment')}>
                        {t('comment')}
                    </Button>
                    {unseenCount?.count > 0 && <div className='round'>{unseenCount?.count}</div>}
                </div>
                <Button
                    className={active === "activity" ? "primary tabButton" : "tabButton"}
                    onClick={() => handleChangeType('activity')}>{t('activity')}</Button>
            </Box>
            <div>
                {active === 'comment'
                    ? <>
                        <div className={style.activityArray} ref={ref}>
                            {data?.results.filter(
                                (obj: any, index: number) => {
                                    return data?.results.findIndex((item: any) => {
                                        let date1 = new Date(obj.created_at);
                                        let date2 = new Date(item.created_at);
                                        date1.setHours(0, 0, 0, 0);
                                        date2.setHours(0, 0, 0, 0);
                                        return date1.getTime() === date2.getTime();
                                    }) === index;
                                }
                            ).map((el: any) => el.created_at).map((date: any, d_ind: number) => {
                                return <div key={d_ind}>
                                    <div className='d-flex justify-content-center'><span className={style.createdAt}>{moment(date).format('DD MMMM YYYY')}</span></div>
                                    {data?.results ? data?.results.filter((m_el: any) => {
                                        let date3 = new Date(m_el.created_at);
                                        let date4 = new Date(date);
                                        date3.setHours(0, 0, 0, 0);
                                        date4.setHours(0, 0, 0, 0);
                                        return date3.getTime() === date4.getTime();
                                    }).sort((a: any, b: any) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()).map((item: any, index: number) => {
                                        const name = item?.creator?.user_role === 1
                                            ? item?.creator?.company_name
                                            : `${item?.creator?.user?.first_name} ${item?.creator?.user?.last_name}`;
                                        return <CommentItem
                                            isMine={item.creator.id == localStorage.getItem('rbcId')}
                                            key={index}
                                            data={item?.data}
                                            avatar={item?.creator?.avatar_image ? `${process.env.REACT_APP_API_KEY}${item?.creator?.avatar_image}` : ''}
                                            name={name}
                                            date={moment(item?.created_at).format('HH:mm')}
                                            description={`${name} ${t(`${item?.description}`)}`}
                                        />;
                                    }) : <span>{t("no_comment")}</span>}
                                </div>;
                            })}
                            {/* {data?.results?.map((el: any, ind: number) => {
                                const name = el?.creator?.user_role === 1 ? el?.creator?.company_name : `${el?.creator?.user?.first_name} ${el?.creator?.user?.last_name}`;
                                return <CommentItem
                                    isMine={el.creator.id == localStorage.getItem('rbcId')}
                                    key={ind}
                                    data={el?.data}
                                    avatar={el?.creator?.avatar_image ? `${process.env.REACT_APP_API_KEY}${el?.creator?.avatar_image}` : ''}
                                    name={name}
                                    date={moment(el?.created_at).format('DD/MM/YYYY HH:mm')}
                                    description={`${name} ${t(`${el?.description}`)}`}
                                />
                            })} */}
                        </div>
                        <div className={`d-flex gap-2 ${style.textareaButton}`}>
                            <textarea rows={1} cols={50} className={style.textarea} value={comment} onChange={handleWriteComment} />
                            <CustomButtosWithIcon
                                title={''}
                                icon={<SendIcon />}
                                type='submit'
                                onClick={() => {
                                    if (comment && comment?.length > 0) {
                                        addActivity({
                                            type: "comment",
                                            data: comment,
                                            creator: localStorage.getItem('rbcId'),
                                            task: params.id
                                        })
                                            .then(() => {
                                                setComment('')
                                                const lastChildElement = ref.current?.firstElementChild;
                                                lastChildElement?.scrollIntoView({ behavior: 'smooth' });
                                            })
                                    }

                                }}
                            />
                        </div>
                    </>
                    : <div className={style.activityArray}>
                        {data?.results?.map((el: any, ind: number) => {
                            const name = el?.creator?.user_role === 1 ? el?.creator?.company_name : `${el?.creator?.user?.first_name} ${el?.creator?.user?.last_name}`;
                            const transitionList = el?.data ? el?.data?.split('->') : [];
                            const elFirst = el?.data ? transitionList[0]?.trim() : '';
                            const elSecond = el?.data ? transitionList[1]?.trim() : '';
                            return <ActivityItem
                                key={ind}
                                data={`${name} ${t(`${el?.description}`)}`}
                                avatar={el?.creator?.avatar_image ? `${process.env.REACT_APP_API_KEY}${el?.creator?.avatar_image}` : ''}
                                name={name}
                                date={moment(el?.created_at).format('DD/MM/YYYY HH:mm:ss')}
                                description={el?.data ? `${t(`${elFirst}`)} -> ${t(`${elSecond}`)}` : ''}
                            />
                        })}
                    </div>}
            </div>
        </Box>
    );
}
