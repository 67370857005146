import { useState, useCallback, useMemo, useEffect } from "react";
import styleProfile from "./editCompany.module.scss";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { Form, Formik, FormikHelpers } from "formik";
import { Params, useNavigate, useParams } from "react-router-dom";
import {
  AddInput,
  CustomButtosWithIcon,
  CustomInput,
  CustomSelect,
  ProfileFileUpload,
} from "../../../Components";
import Close from "../../../Components/SvgCompoents/Close";
import { useGetManagersQuery } from "../../../store/services/managers";
import {
  useAddCompaniesMutation,
  useEditCompaniesMutation,
  useGetCompaniesQuery,
  useGetCompanyByIdQuery,
} from "../../../store/services/companies";
import { useAddImagesMutation } from "../../../store/services/photo";
import ChangePhoto from "../../../Components/SvgCompoents/ChangePhoto";
import { toast } from "react-toastify";
import EditUser from "../../../Components/SvgCompoents/EditUser";
import DeleteAccountDialog from "../../../Dialogs/DeleteAccountDialog";


export const EditCompany = () => {
  const { t } = useTranslation();
  const navigate = useNavigate(); 
  const [disable, setDisable] = useState(true);
  const [open, setOpen] = useState(false);
  const params: any = useParams();
  const { data: companyData, isLoading }: any = useGetCompanyByIdQuery(+params?.id);
  const [accountantList, setAccountantList] = useState<string[]>(companyData?.accountant_worker ? companyData?.accountant_worker?.map((el: any) => el?.id) : []);
  const [addressList, setAddressList] = useState<any>(companyData?.address);
  const [managerList, setManagerList] = useState<string[]>(
    companyData?.managers ? companyData?.managers.map((el: any) => el.id) : []
  );
  const { data: managers } = useGetManagersQuery({ limit: 100000, offset: 0, client_id: companyData?.creator });
  const [addImages, { data }] = useAddImagesMutation();
  const [editCompany, { data: editedCompanyData }] = useEditCompaniesMutation();
  const myId = localStorage.getItem('rbcRole');
  const handleEdit = useCallback(
    () =>
      setTimeout(() => {
        setDisable(false);
      }, 1000),
    []
  );
  useEffect(() => {
    setAddressList(companyData?.address);
  }, [companyData?.address]);
  useEffect(() => {
    setAccountantList(companyData?.accountant_worker.map((el: any) => el?.id))
  }, [companyData?.accountant_worker])
  useEffect(() => {    
    setManagerList(companyData?.managers.map((el: any) => el.id));
  }, [companyData?.managers]);

  const SignupSchema = Yup.object().shape({
    name: Yup.string().required(t("required")),
    hvhh: Yup.string().required(t("required")),
    // addresses: Yup.array().required(t("required")),
  });
  interface Values {
    name: string;
    hvhh: string;
    managers: number[];
  }
  const handleAddAddress = useCallback(
    (address: string) => {
      const newArray = address
        ? [...addressList, { name: address }]
        : [...addressList];
      setAddressList(newArray);
    },
    [addressList]
  );

  const handleDeleteAddress = useCallback(
    (ind: number) => {
      const newArray = [...addressList];
      newArray.splice(ind, 1);
      setAddressList(newArray);
    },
    [addressList]
  );
  const handleOpen = useCallback(() => {
    setOpen(true)
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false)
  }, []);
  const handleAddManager = useCallback(
    (managerId: any) => {
      // const selected = managerList.find((element) => element === managerId)
      // const newArray = selected ? managerList.filter((el) => el !== selected) : [...managerList, managerId];
      setManagerList(managerId);
    },
    [managerList]
  );

  const handleDeleteManager = useCallback(
    (ind: number) => {
      const newArray = [...managerList];
      newArray.splice(ind, 1);
      setManagerList(newArray);
    },
    [managerList]
  );

  const selectedManagers = useMemo(() => {
    let newArray: any = [];
    for (let index = 0; index < managerList?.length; index++) {
      const element = managerList[index];
      for (let ind = 0; ind < managers?.length; ind++) {
        const el = managers[ind];
        if (el.id === +element) {
          newArray.push(el);
        }
      }
    }
    return newArray;
  }, [managerList]);

  return isLoading ? (
    <div></div>
  ) : (
    <div className={`w-100`}>
      <Formik
        initialValues={{
          name: companyData?.name,
          hvhh: companyData?.hvhh,
          managers: [110],
        }}
        validationSchema={SignupSchema}
        onSubmit={(
          values: Values,
          { setSubmitting }: FormikHelpers<Values>
        ) => {
          editCompany({
            ...values,
            id: params?.id,
            managers: managerList.length > 0 ? managerList : [],
            accountant_worker: accountantList?.length > 0 ? accountantList : [],
            address: addressList,
            image: data ? data : companyData.image,
          })
            .then((res: any) => {
              if (res.data) {
                toast.success(t('editProfileSuccess'))
                setDisable(true)
              }
            })
        }}
      >
        {({ errors, touched }: any) => (
          <Form>
            <DeleteAccountDialog
            title={companyData?.is_active ?  t('doYouWantDeactivate') : t('doYouWantActivate')}
            open={open}
            handleClose={handleClose}
            handleDelete={() => {
              editCompany({
                id: params?.id,
                is_active: !companyData?.is_active
              })
                .then((res: any) => {
                  if (res.data) {
                    toast.success(t('editProfileSuccess'));
                    setOpen(false)
                  }
                })
            }}
          />
            <div className={styleProfile.profilePhotos}>
              {/* <h1 className={styleProfile.myProfile}>Edit company data</h1> */}
              <div
                className={`w-100 d-flex justify-content-between ${styleProfile.nameImages}`}
              >
                <div className="d-flex flex-wrap justify-content-between align-items-center w-100">
                  {/* <div className={styleProfile.profilePhotoEdit}>
                    {data ? (
                      <img
                        src={`${process.env.REACT_APP_API_KEY}${data}`}
                        className={styleProfile.profilePhoto}
                      />
                    ) : companyData?.image ? (
                      <img
                        src={`${process.env.REACT_APP_API_KEY}${companyData.image}`}
                        className={styleProfile.profilePhoto}
                      />
                    ) : (
                      <div className={styleProfile.imgContainer}>
                        <img src={"/companyDefaultImg.svg"} />
                      </div>
                    )}
                    <label>
                      <input
                        type="file"
                        style={{ display: "none" }}
                        onChange={(e: any) => {
                          const file: any = e?.target?.files[0];
                          if (file) {
                            const formdata = new FormData();
                            formdata.append("file_url", file);
                            addImages(formdata).then((res: any) => {
                              editCompany({
                                id: params?.id,
                                image: res?.data,
                              })
                            });
                          }
                        }}
                      />
                      <div className={styleProfile.changePhotoCont}>
                        <img src={"/camera.svg"} />
                      </div>
                    </label>
                  </div> */}
                  <ProfileFileUpload
                    forCompany={true}
                    avatar_image={companyData?.image
                      ? `${process.env.REACT_APP_API_KEY}${companyData?.image}`
                      : ''}
                    request={(img: string) => {
                      editCompany({
                        id: params?.companyid,
                        image: img,
                      })
                    }} />
                    <div className={`d-flex flex-wrap justify-content-center gap-2 justify-md-content-between ${styleProfile.buttonsCont}`}>
                  <CustomButtosWithIcon
                    color='red'
                    title={companyData?.is_active ? t('deactivate') : t('activate')}
                    type={'button'}
                    onClick={handleOpen}
                    // icon={< />}
                  />
                  {companyData?.is_active && (myId === '4' ? <></> : (disable ? (
                    <div>
                      <CustomButtosWithIcon
                        title={t("editProfile")}
                        type={"button"}
                        onClick={handleEdit}
                        icon={<EditUser />}
                      />
                    </div>
                  ) : (
                    <div>
                      <CustomButtosWithIcon
                        title={t("save")}
                        type="submit"
                        onClick={console.log("ggg")}
                        icon={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M10 0C4.48583 0 0 4.48583 0 10C0 15.5142 4.48583 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48583 15.5142 0 10 0ZM9.92417 12.8492C9.61113 13.1596 9.18791 13.3334 8.74706 13.3326C8.3062 13.3319 7.8836 13.1565 7.57167 12.845L5.25333 10.5983L6.41417 9.40083L8.74167 11.6567L13.5825 6.90583L14.7525 8.09333L9.92417 12.8492Z"
                              fill="#FEFEFE"
                            />
                          </svg>
                        }
                      />
                    </div>
                  )))}
                </div>
                </div>
              </div>
            </div>
            <div className={styleProfile.formsCont}>
              <div
                className={`d-flex flex-wrap justify-content-between ${styleProfile.inputs}`}
              >
                <CustomInput readonly={disable} name="name" title={t("companyName")} />
                <CustomInput readonly={disable} name="hvhh" title={t("HVHH")} />
                <div className={`${styleProfile.withSelected} ${styleProfile.addresses}`}>
                  <AddInput
                    readonly={disable}
                    label={t("addresses")}
                    addAddress={(val: string) => handleAddAddress(val)}
                  />
                  {addressList?.map((el: any, ind: number) => (
                    <div key={ind} className={`${styleProfile.selected} ${styleProfile.selectedAddress}`}>
                      <span className={styleProfile.title}>{el.name}</span>
                      {!disable && <span onClick={() => handleDeleteAddress(ind)}>
                        <Close />
                      </span>}
                    </div>
                  ))}
                </div>
                {managers?.length > 0 && <div className={styleProfile.withSelected}>
                  <CustomSelect
                    readonly={disable}
                    label={t("managers")}
                    list={managers?.map((el: any) => ({
                      name: `${el.user.first_name} ${el.user.last_name}`,
                      id: el.id,
                    }))}
                    value={managerList}
                    multiple={true}
                    onChange={handleAddManager}
                  />
                  {selectedManagers?.map((el: any, ind: number) => {
                    return (
                      <div key={ind} className={styleProfile.selected}>
                        {el?.avatar_image
                          ? <img
                            src={`${process.env.REACT_APP_API_KEY}${el?.avatar_image}`}
                            className={styleProfile.image}
                          />
                          : <div><img
                            src={"/managerDefaultImg.svg"}
                            className={styleProfile.imageDefault}
                          /></div>}
                        <span className={styleProfile.title}>
                          {el?.user?.first_name} {el?.user?.last_name}
                        </span>
                        {!disable && <span onClick={() => handleDeleteManager(ind)}>
                          <Close />
                        </span>}
                      </div>
                    );
                  })}
                </div>}
                {/* {companyData?.accountant_worker?.length > 0 && <div className={`${styleProfile.withSelected} ${styleProfile.withSelectedAccountant}`}>
                  <CustomSelect
                    readonly={true}
                    label={t("accountants")}
                    list={managers?.map((el: any) => ({
                      name: `${el.user.first_name} ${el.user.last_name}`,
                      id: el?.id,
                    }))}
                    value={accountantList}
                    multiple={true}
                    onChange={() => console.log('aaa')}
                  />
                  {companyData?.accountant_worker?.map((el: any, ind: number) => {
                    return (
                      <div key={ind} className={styleProfile.selected}>
                        {el?.avatar_image
                          ? <img
                            src={`${process.env.REACT_APP_API_KEY}${el?.avatar_image}`}
                            className={styleProfile.image}
                          />
                          : <div><img
                            src={"/managerDefaultImg.svg"}
                            className={styleProfile.imageDefault}
                          /></div>}
                        <span className={styleProfile.title}>
                          {el?.user?.first_name} {el?.user?.last_name}
                        </span>

                      </div>
                    );
                  })}
                </div>} */}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
