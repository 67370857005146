import styleProfile from './AddEmployees.module.scss'
import { useTranslation } from 'react-i18next';
import * as Yup from "yup";
import { Form, Formik, FormikHelpers } from 'formik';
import { CustomButtosWithIcon, CustomInput } from '../../../Components';
import { useAddImagesMutation } from '../../../store/services/photo';
import ChangePhoto from '../../../Components/SvgCompoents/ChangePhoto';
import { useAddManagersMutation } from '../../../store/services/managers';


export default function AddEmployees() {
  const { t, i18n } = useTranslation();

  interface Values {
    first_name: string;
    last_name: string;
    phone_number?: string;
    email: string;
    // password: string;
    // repeat_password: string
  }
  const SignupSchema = Yup.object().shape({
    first_name: Yup.string()
      .required(t('required')),
    last_name: Yup.string()
      .required(t('required')),
      phone_number: Yup.string()
      .required(t("required"))
      .test(
        (value:any) => /^[0-9\b]+$/.test(value)
      ),
    email: Yup.string().trim().email(t('emailError'))
      .required(t('required')),
    // password: Yup.string()
    //   .required(t('required')),
    // repeat_password: Yup.string()
    //   .required(t('required')),
  });

  const [addImages, { data }] = useAddImagesMutation();
  const [addManagerRequest, { isSuccess }] = useAddManagersMutation();

  return (
    <div className={`w-100`}>
      <div className={styleProfile.profilePhotos}>
        {/* <h1 className={styleProfile.myProfile}>{t('addEmployees')}</h1> */}
        <div className={`w-100 d-flex justify-content-between ${styleProfile.nameImages}`}>
          <div className='d-flex flex-wrap justify-content-between align-items-center w-100'>
            <div className={styleProfile.profilePhotoEdit}>
              {data ? <img src={`${process.env.REACT_APP_API_KEY}${data}`} className={styleProfile.profilePhoto} /> : <div className={styleProfile.imgContainer}><img src={`/managerDefaultImg.svg`} /></div>}
              <label>
                <input
                  type='file'
                  style={{ display: 'none' }}
                  onChange={(e: any) => {
                    const file: any = e?.target?.files[0];
                    if (file) {
                      const formdata = new FormData();
                      formdata.append('file_url', file)
                      addImages(formdata)
                        .then((res) => {
                          // editRequest(values)
                        });
                    }
                  }}
                />
                <ChangePhoto />
              </label>
            </div>
            <CustomButtosWithIcon
              title={t('save')}
              icon={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M10 0C4.48583 0 0 4.48583 0 10C0 15.5142 4.48583 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48583 15.5142 0 10 0ZM9.92417 12.8492C9.61113 13.1596 9.18791 13.3334 8.74706 13.3326C8.3062 13.3319 7.8836 13.1565 7.57167 12.845L5.25333 10.5983L6.41417 9.40083L8.74167 11.6567L13.5825 6.90583L14.7525 8.09333L9.92417 12.8492Z" fill="#FEFEFE" />
              </svg>}
              type='submit'
              onClick={() => console.log()}
            />
          </div>
        </div>
      </div>
      <div className={styleProfile.formsCont}>
        <Formik
          initialValues={{
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            repeat_password: ''
          }}
          validationSchema={SignupSchema}
          onSubmit={(
            values: Values,
            { setSubmitting }: FormikHelpers<Values>
          ) => {
            addManagerRequest({
              ...values,
              role: 3,
              legal_type: 1,
              tin: "",
              company_name: "",
              birth_date: new Date(),
              language: i18n.language==='am'?'hy':i18n.language,
              cover_image: "",
              avatar_image: data
            });
          }}
        >
          {({ errors, touched }: any) => (
            <Form >
              <div className={`d-flex flex-wrap justify-content-between ${styleProfile.inputs}`}>
                <CustomInput name='first_name' title={t('firstName')} />
                <CustomInput name='last_name' title={t('lastName')} />
                <CustomInput name='phone_number' title={t('phoneNumber')} />
                <CustomInput name='email' title={t('emailAdress')} />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}


