import { useState, useCallback, useMemo, useEffect } from "react";
import styleProfile from "./AddCompany.module.scss";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { Form, Formik, FormikHelpers } from 'formik';
import { useNavigate } from 'react-router-dom';
import { AddInput, CustomButtosWithIcon, CustomInput, CustomSelect, ProfileFileUpload } from '../../../Components';
import Close from '../../../Components/SvgCompoents/Close';
import { useGetManagersQuery } from '../../../store/services/managers';
import { useAddCompaniesMutation } from '../../../store/services/companies';
import { useAddImagesMutation } from '../../../store/services/photo';
import ChangePhoto from '../../../Components/SvgCompoents/ChangePhoto';
import { toast } from 'react-toastify';

export default function AddCompany() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [addressList, setAddressList] = useState<any>([]);
  const [managerList, setManagerList] = useState<string[]>([]);
  const [avatarImg, setAvatarImg] = useState<string>();
  const [submited, setSubmit] = useState<boolean>(false);
  const { data: managers } = useGetManagersQuery({ limit: 100000, offset: 0 });
  const [addCompaniesRequest, { data: companies, isSuccess }] =
    useAddCompaniesMutation();

  useEffect(() => {
    if (isSuccess) toast.success(t("createCompanySuccess"));
  }, [isSuccess]);

  const SignupSchema = Yup.object().shape({
    name: Yup.string().required(t("required")),
    hvhh: Yup.string().required(t("required")),
  });
  interface Values {
    name: string;
    hvhh: string,
    managers?: number[]
  }
  const handleAddAddress = useCallback(
    (address: string) => {
      const newArray = address
        ? [...addressList, { name: address }]
        : [...addressList];
      setAddressList(newArray);
    },
    [addressList]
  );

  const handleDeleteAddress = useCallback(
    (ind: number) => {
      const newArray = [...addressList];
      newArray.splice(ind, 1);
      setAddressList(newArray);
    },
    [addressList]
  );

  const handleAddManager = useCallback(
    (managerId: any) => {
      setManagerList(managerId);
    },
    [managerList]
  );

  const handleSubmit = useCallback(() => {
    setSubmit(true);
  },
    [managerList]
  );

  const handleDeleteManager = useCallback(
    (ind: number) => {
      const newArray = [...managerList];
      newArray.splice(ind, 1);
      setManagerList(newArray);
    },
    [managerList]
  );
  const handleSetImages = useCallback((img: string) => {
    setAvatarImg(img)
  }, [])
  const selectedManagers = useMemo(() => {
    let newArray: any = [];
    for (let index = 0; index < managerList?.length; index++) {
      const element = managerList[index];
      for (let ind = 0; ind < managers?.length; ind++) {
        const el = managers[ind];
        if (el.id === +element) {
          newArray.push(el);
        }
      }
    }
    return newArray;
  }, [managerList]);

  const [addImages, { data }] = useAddImagesMutation();

  return (
    <div className={`w-100`}>
      <Formik
        initialValues={{
          name: '',
          hvhh: '',
        }}
        validationSchema={SignupSchema}
        onSubmit={(
          values: Values,
          { setSubmitting }: FormikHelpers<Values>
        ) => {
          if (addressList.length > 0) {
            addCompaniesRequest({
              ...values,
              managers: managerList.length > 0 ? managerList : [],
              addresses: addressList,
              image: avatarImg ? avatarImg : "",
              tarif:1
            })
              .then((res: any) => {
                if (res.data.id) {
                  navigate('/profile/companies')
                }
              })
          }
        }}
      >
        {({ errors, touched }: any) => (
          <Form>
            <div className={styleProfile.profilePhotos}>
              {/* <h1 className={styleProfile.myProfile}>{t("addCompany")}</h1> */}
              <div
                className={`w-100 d-flex justify-content-between ${styleProfile.nameImages}`}
              >
                <div className="d-flex flex-wrap justify-content-between align-items-center w-100">
                  <ProfileFileUpload
                    forCompany={true}
                    avatar_image={avatarImg ? `${process.env.REACT_APP_API_KEY}${avatarImg}` : ''}
                    request={handleSetImages} />
                  <CustomButtosWithIcon
                    title={t("save")}
                    icon={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M10 0C4.48583 0 0 4.48583 0 10C0 15.5142 4.48583 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48583 15.5142 0 10 0ZM9.92417 12.8492C9.61113 13.1596 9.18791 13.3334 8.74706 13.3326C8.3062 13.3319 7.8836 13.1565 7.57167 12.845L5.25333 10.5983L6.41417 9.40083L8.74167 11.6567L13.5825 6.90583L14.7525 8.09333L9.92417 12.8492Z"
                          fill="#FEFEFE"
                        />
                      </svg>
                    }
                    type="submit"
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            </div>
            <div className={styleProfile.formsCont}>
              <div
                className={`d-flex flex-wrap justify-content-between ${styleProfile.inputs}`}
              >
                <CustomInput name="name" title={t("companyName")} />
                <CustomInput name="hvhh" title={t("HVHH")} />
                <div className={styleProfile.withSelected}>
                  <AddInput
                    label={t("addresses")}
                    addAddress={(val: string) => handleAddAddress(val)}
                  />
                  {submited && addressList.length === 0 && <div className='error'>{t('required')}</div>}
                  {addressList?.map((el: any, ind: number) => (
                    <div key={ind} className={`${styleProfile.selected} ${styleProfile.selectedAddress}`}>
                      <span className={styleProfile.title}>{el.name}</span>
                      <span onClick={() => handleDeleteAddress(ind)}>
                        <Close />
                      </span>
                    </div>
                  ))}
                </div>
                {managers?.length > 0 && <div className={styleProfile.withSelected}>
                  <CustomSelect
                    label={t("managers")}
                    list={managers?.map((el: any) => ({
                      name: `${el.user.first_name} ${el.user.last_name}`,
                      id: el.id,
                    }))}
                    value={managerList}
                    multiple={true}
                    onChange={handleAddManager}
                  />
                  {selectedManagers?.map((el: any, ind: number) => {
                    return (
                      <div key={ind} className={styleProfile.selected}>
                        {el?.avatar_image
                          ? <img
                            src={`${process.env.REACT_APP_API_KEY}${el?.avatar_image}`}
                            className={styleProfile.image}
                          />
                          : <div><img
                            src={"/managerDefaultImg.svg"}
                            className={styleProfile.imageDefault}
                          /></div>}
                        <span className={styleProfile.title}>
                          {el?.user?.first_name} {el?.user?.last_name}
                        </span>
                        <span onClick={() => handleDeleteManager(ind)}>
                          <Close />
                        </span>
                      </div>
                    );
                  })}
                </div>}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
